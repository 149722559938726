@import "@causevest/ui-kit/variables";

.resultsPopup {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: $color-white;
  z-index: 9999;
  box-shadow: 0 2px 30px rgba(13, 58, 115, 0.1);

  &__block {

    > a {
      text-decoration: none;
      color: $color-black;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-light-grey;
    }
  }

  &__heading {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    padding: 8px 8px 8px 10px;
    border-bottom: 1px solid $color-light-grey;
  }

  &__item {
    padding: 8px;
    gap: 8px;
    transition: background-color 0.2s linear;

    &:hover {
      background: $color-bg-blue;
    }
  }

  &__image {
    overflow: hidden;
    border-radius: 50%;
  }

  &__title {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    margin: 0;
  }
}
